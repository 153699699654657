import * as React from "react"
import Layout from '../components/layout'

const AboutPage = () => {
    return (
        <Layout pageTitle="About Page">
            <p> This page is about Sarah. </p>
        </Layout>
    )
}

export default AboutPage